import * as Immutable from 'immutable';
import Position from './Position';
import dayjs from 'dayjs';
import { Colors } from '../style/colors';
import {AlarmHandler} from './AlarmHandler';
import {deviceTypes, deviceTypesInv, getManufacturer, realDeviceTypes} from './DeviceType';
import Account from './Account';
import Scenario from './Scenario';
import {
	BatteryAlertIcon,
	BatteryFullIcon,
	BatteryHalfIcon,
	BatteryOffOutlineIcon
} from '../icons';
import {getWhitelabel} from "../../env";
import {shortDateTimeFormat, sortableDateTimeFormat} from "../style/formats";
import {Tooltip} from "@mui/material";
import {getI18n} from "react-i18next";
import {consoleError} from "../log";
import {scenarioTriggers} from "./scenarios/scenarioTriggers";

export const activeState = {
	toActivate: 0,
	toConfigure: 1,
	active: 2
};
export const configState = {
	neverConfigured: 0,
	configuring: 1,
	configured: 2,
	needsReboot: 3,
	willReboot: 4,
	error: 5
};

const DeviceModel = Immutable.Record({
	id: undefined,
	name: '',
	accountName: '',
	arcKey: '',
	industry: '',
	tempAddress: '',
	tempAddressAutoClear: '',
	tempAddressExpiration: 0,
	operatingAddress: '',
	operatingLat: '',
	operatingLng: '',
	instructions: '',
	imei: undefined, //deprecated
	uniqueId: undefined,
	iccid: undefined,
	blemac: undefined,
	phone: undefined,
	status: undefined,
	activeState: undefined,
	configState: undefined,
	lastUpdate: 0,
	created: 0,
	deleted: 0,
	beaconIds: [],
	locating: false,
	prom: undefined,
	safeword: '',
	panicword: '',
	mute: false,
	productName: '',
	accountId: 0,
	protocolId: null,
	deviceTypeId: 1,
	hasDevCon: true,
	trackingInterval: null,
	creatorId: 0,
	recording: null,
	os: '',
	version: 0,
	firmware: '',
	firmwareSupported: true,
	extraFields: [],
	urls: [],
	wifiOnly: false,
	ip: '',
	battery: null,
	batteryDate: null,

	wifi: false,
	srd: false,
	gnss: false,
	md: false,
	ble: false,
	fourG: false,
	threeG: false,
	voLTE: false,
	ripcord: false,
	compatibility: {},
	protocol: {},
	devconSectionsDisabled: [],
	previousPositions: [],

	locationForeground: false,
	locationBackground: false,
	locationPossible: 'yes',

	apn: '',
	apnUser: '',
	apnPass: '',
	apnOperator: '',
	dns1: '',
	dns2: '',
	pin: '',

	moduleIds: [],
	
	_position: {}
});

export default class Device extends DeviceModel {
	id: number;
	name: string;
	accountName: string;
	industry: string;
	arcKey: string;
	tempAddress: string;
	tempAddressExpiration: number;
	tempAddressAutoClear: string;
	operatingAddress: string;
	operatingLat: number;
	lastUpdate: number;
	mute: boolean;
	operatingLng: number;
	instructions: string;
	imei: string; //deprecated
	uniqueId: string;
	iccid: number;
	phone: number;
	status: string;
	activeState: number;
	created: number;
	deleted: number;
	beaconIds: number[];
	prom: string;
	accountId: number;
	hasDevCon: boolean;
	protocolId: number;
	deviceTypeId: number;
	trackingInterval: number;
	creatorId: number;
	recording: Object;
	os: string;
	version: number;
	firmware: string;
	firmwareSupported: boolean;
	extraFields: Object[];
	urls: Object[];
	locationForeground: boolean;
	locationBackground: boolean;
	locationPossible: string;
	wifiOnly: boolean;
	ip: string;
	battery: ?number;
	batteryDate: ?number;
	previousPositions: Position[];

	wifi: boolean;
	srd: boolean;
	gnss: boolean;
	ble: boolean;
	threeG: boolean;
	fourG: boolean;
	voLTE: boolean;
	ripcord: boolean;
	md: boolean;
	compatibility: any;
	protocol: any;
	devconSectionsDisabled: string[];

	apn: string;
	apnUser: string;
	apnPass: string;
	apnOperator: string;
	dns1: string;
	dns2: string;
	pin: string;

	moduleIds: number[]

	_position: {};
	
	static protocols = null;
	
	getProtocol() {
		return ((Device.protocols || {})[this.deviceTypeId]) || {};
	}

	get countsForLimit() {
		return [
			deviceTypesInv.App,
			deviceTypesInv.WebUser,
			deviceTypesInv.TwigRemoteButton
		].indexOf(this.deviceTypeId) === -1;
	}
	get hasWhitelist() {
		if(this.isTeltonika || this.isTwig) return true;
		return [
			deviceTypesInv.EviewEV04
		].indexOf(this.get('deviceTypeId')) >= 0;
	}
	get canTrack() {
		if(!this.can('tracking')) return false;
		if(this.isTwig) return this.gnss || this.srd || this.ble || this.wifi;
		return this.gnss || this.ble;
	}
	get canTrackIndoor() {
		if(this.isTwig) return this.srd || this.ble || this.wifi;
		return this.ble;
	}
	
	get isWebUser() {
		return this.get('deviceTypeId') === deviceTypesInv.WebUser;
	}
	get isGenerated() {
		return [
			deviceTypesInv.WebUser,
			deviceTypesInv.TwigRemoteButton
		].indexOf(this.get('deviceTypeId')) >= 0;
	}
	
	get tempAddressClearObject() {
		if(!this.get('tempAddressAutoClear')) return { timeout: 24 };
		if(this.get('tempAddressAutoClear').indexOf('to') === 0) {
			return { timeout: +this.get('tempAddressAutoClear').replace('to', '') };
		} else if(this.get('tempAddressAutoClear').indexOf('time') === 0) {
			return { time: +this.get('tempAddressAutoClear').replace('time', '') };
		}
		return { timeout: +this.get('tempAddressAutoClear') };
	}

	get inError() : boolean {
		if(!this.isActive) return false;
		if(this.hasConfigError) return true;
		if(this.isApp) {
			if (!this.get('version')) return false;
			if (!this.isLocationPossible) return true;
			if (!this.locationPermitted) return true;
		}
		return this.hasFirmwareError;
	}
	
	get previousPositionsList() {
		if(!this.get('previousPositions')?.map) {
			return [];
		} else {
			const positions = [...this.get('previousPositions')];
			positions.shift();
			return positions;
		}
	}
	get hasFirmwareError() : boolean {
		return !this.get('firmwareSupported') && !!this.get('firmware');
	}

	get hasConfigError() {
		return this.get('configState') === configState.error;
	}

	get alarmHandler() : AlarmHandler {
		if(this.get('arcKey')) {
			return AlarmHandler.ARC;
		}
		return null;
	}

	get hasOsm() {
		return this.can('osm');
	}

	get isApp() {
		return this.deviceTypeId === deviceTypesInv.App;
	}
	get isEmerit() {
		return [
			deviceTypesInv.EmeritConnect,
			deviceTypesInv.EmeritEWG100,
			deviceTypesInv.EmeritERG170,
			deviceTypesInv.EmeritERG530,
			deviceTypesInv.EmeritERG650,
			deviceTypesInv.EmeritERG655,
			deviceTypesInv.EmeritEIS330,
			deviceTypesInv.EmeritEIS530,
			deviceTypesInv.EmeritEIS655
		].indexOf(this.deviceTypeId) >= 0;
	}
	get isApple() {
		return this.isApp && this.get('os') === 'ios';
	}
	get isGeneric() {
		return this.deviceTypeId === deviceTypesInv.Generic;
	}

	can(functionality: string) {
		return !!(this.get('compatibility') || {})[functionality];
	}

	hasModule(modId: number) {
		return this.get('moduleIds').indexOf(modId) >= 0;
	}
	toggleModule(modId: number) {
		let mods = this.get('moduleIds');
		const i = this.get('moduleIds').indexOf(modId);
		i >= 0 ? mods.splice(i, 1) : mods.push(modId);
		return this.set('moduleIds', mods);
	}

	get alarmType() {
		switch (this.get('deviceTypeId')) {
			case deviceTypesInv.TeltonikaFMC640: return null;
			case deviceTypesInv.TeltonikaFMC650: return null;
			case deviceTypesInv.TeltonikaTMT250: return 'deskAlarm';
			case deviceTypesInv.TeltonikaTMT250Old: return 'deskAlarm';
			case deviceTypesInv.Generic: return 'genericAlarm';
			default: return 'personalAlarm';
		}
	}

	get canConfigureSimPin() {
		return this.isTeltonika;
	}

	get isIdle() : boolean {
		return this.get('status') === 'idle';
	}
	get connected() : boolean {
		return this.get('status') === 'online' || this.isIdle;
	}

	get color() : string {
		if(this.deviceTypeId === deviceTypesInv.Generic) return null;
		if(this.isIdle) return Colors.idle;
		return this.connected ? Colors.green : Colors.gray30;
	}

	getName(t) : string {
		if(this.get('name') === '#account#') return getWhitelabel().platformName;
		if(this.get('name').indexOf('shadow_') === 0) return t('accountManager');
		return this.get('name');
	}
	deviceTypeName(t) : string {
		if(deviceTypes[this.get('deviceTypeId')]) {
			return deviceTypes[this.get('deviceTypeId')](t);
		}
		consoleError(`Unknown device type '${this.get('deviceTypeId')}'`);
		return t('unknownDeviceType');
	}
	realDeviceTypeName(t) : string {
		if(realDeviceTypes[this.get('deviceTypeId')]) {
			return realDeviceTypes[this.get('deviceTypeId')](t);
		}
		consoleError(`Unknown device type '${this.get('deviceTypeId')}'`);
		return t('unknownDeviceType');
	}

	setIsOwnSimCard(isOwnSimcard) {
		if(isOwnSimcard) {
			return this.set('iccid', '');
		} else {
			return this.set('phone','').set('apn','').set('apnUser','').set('apnPass','').set('dns1','').set('dns2','').set('pin','');
		}
	}
	setWifiOnly(wifiOnly) {
		if(!wifiOnly) return this.set('wifiOnly', !this.get('wifiOnly'));
		return this.setIsOwnSimCard(false)
			.set('iccid', '')
			.set('wifiOnly', !this.get('wifiOnly'));
	}
	
	get wifiOnlyPossible() {
		if(this.isTwigP5) {
			return !!this.get('wifi');
		}
		return this.isEmerit;
	}

	get isIncomplete() : boolean {
		return this.hasMissingFields && !!this.alarmHandler;
	}

	get hasMissingFields() : boolean {
		return !this.isWebUser && (!this.get('industry') || !this.get('operatingAddress'));
	}

	get isActive() : boolean {
		return this.get('activeState') === activeState.active;
	}

	get isConfiguring() : boolean {
		if(this.isApp) return false;
		return [configState.configuring, configState.willReboot].indexOf(this.get('configState')) >= 0;
	}
	get configError() : boolean {
		return this.get('configState') === configState.error;
	}

	get tempAddressExpirationDate() {
		if(!this.tempAddressExpiration) return null;
		return dayjs.unix(this.tempAddressExpiration);
	}
	get lastUpdateTime() : dayjs {
		return dayjs.unix(this.lastUpdate || 0);
	}
	get lastUpdateTimeExport() : dayjs {
		return this.lastUpdate ? this.lastUpdateTime.format(sortableDateTimeFormat) : '';
	}

	get needsReboot() : boolean {
		return this.get('configState') === configState.needsReboot;
	}

	get position() : Position {
		return new Position(this.get('_position') || {});
	}

	get isTwigP5() {
		return [
			deviceTypesInv.TwigOne,
			deviceTypesInv.TwigOneEx,
			deviceTypesInv.TwigNeo,
			deviceTypesInv.TwigSOSCardP5
		].indexOf(this.get('deviceTypeId')) >= 0;
	}
	get isTwig() {
		return getManufacturer(this.get('deviceTypeId')) === 'twig';
	}
	get isEV04() {
		return getManufacturer(this.get('deviceTypeId')) === 'eview';
	}
	get isTeltonika() {
		return getManufacturer(this.get('deviceTypeId')) === 'teltonika';
	}
	get canPowerOff() {
		if(this.isTwig) return true;
		return [
			deviceTypesInv.EviewEV04,
			deviceTypesInv.QueclinkGL320M
		].indexOf(this.get('deviceTypeId')) >= 0;
	}


	get operatingPosition() : Position {
		return new Position({
			latitude: this.operatingLat,
			longitude: this.operatingLng,
			address: this.operatingAddress
		});
	}

	get locationPermitted() : boolean {
		if(!this.isApp) return true;
		return !!this.get('locationForeground') && !!this.get('locationBackground');
	}
	get isLocationPossible() : boolean {
		if(!this.isApp) return true;
		return this.get('locationPossible') !== 'no';
	}

	get statusName() : string {
		if(this.deviceTypeId === deviceTypesInv.Generic) {
			return 'noStatusAvailable';
		}
		if(this.isIdle) return 'indeterminate';
		return this.connected ? 'connected' : 'notConnected';
	}

	constructor(props) {
		if(!props) props = {};
		props._position = props?.position || {}; 
		delete props.position;
		super(props);
	}
	
	get ripcordPossible() {
		return [
			deviceTypesInv.TwigEmbody,
			deviceTypesInv.TwigNeo,
			deviceTypesInv.TwigSOSCard,
			deviceTypesInv.TwigSOSCardP5
		].indexOf(this.deviceTypeId) >= 0;
	}

	isCompatibleWithTrigger(trigger) {
		if(!Scenario.compatibility[this.deviceTypeId]) return false;
		if(!Scenario.compatibility[this.deviceTypeId][trigger]) return false;

		if(trigger === scenarioTriggers.configError) return this.can('devCon');
		if(trigger === scenarioTriggers.manDown) return this.get('md');
		if(trigger === scenarioTriggers.ripcord) return this.get('ripcord');
		if(trigger === scenarioTriggers.beaconBattery) return this.get('srd');
		if(trigger === scenarioTriggers.connectionChanged) {
			if(this.isTeltonika) return !!this.iccid;
		}
		return true;
	}

	get createdDate() {
		if(this.get('created'))
			return dayjs.unix(this.get('created'));
		return null;
	}
	get deletedDate() {
		if(this.get('deleted'))
			return dayjs.unix(this.get('deleted'));
		return null;
	}

	clone() {
		return this.set('id', this.id);
	}

	static create(creatorId: number, name: string = '', account: Account = null) {
		return new Device({
			name,
			creatorId: +creatorId,
			lastUpdate: dayjs().unix(),
			position: null,
			arcKey: '',
			deviceTypeId: 0,
			accountId: account ? account.id : null
		});
	}

	get canReceiveAssistanceRequests() {
		return this.isApp && +this.get('version') >= 30;
	}
	
	filterString(t) {
		return [
			this.id,
			this.name,
			this.deviceTypeName(t),
			this.ble,
			this.beaconIds,
			this.iccid,
			this.uniqueId,
			this.accountName,
			this.accountId,
			...this.get('extraFields').map(f => f.name + f.value)
		].join('').lc();
	}
	
	get BatteryIcon() {
		let Icon = BatteryAlertIcon;
		let color = Colors.warning;
		let title;
		const t = getI18n().t;
		if(this.battery) {
			title = `${this.get('battery')} %`;
			if(this.get('batteryDate')) {
				title += ` - ${t('lastUpdate')} ${dayjs.unix(this.get('batteryDate')).format(shortDateTimeFormat)}`;
			}
			if (+this.battery > 80) {
				Icon = BatteryFullIcon;
				color = Colors.green;
			} else if (+this.battery > 30) {
				Icon = BatteryHalfIcon;
				color = Colors.gray40;
			}
		} else {
			Icon = BatteryOffOutlineIcon;
			color = Colors.gray30;
			title = t('unavailable')
		}
		return (
			<Tooltip title={title}>
				<span>
					<Icon color={color} />
				</span>
			</Tooltip>
		);
	}
}
