import { locales } from '../../i18n';
import jwtDecode from 'jwt-decode';
import BaseAPI from './API/BaseAPI';
import pageMap from '../pageMap';
import _isEqual from "lodash/isEqual";
import {isValidPhoneNumber as _isValidPhoneNumber} from 'libphonenumber-js'
import dayjs from "dayjs";
import getReference from "./getReference";
import validator from 'validator';

let emergencyNumbersJson = localStorage.getItem('emergencyCallNumbers');
if(!emergencyNumbersJson || true) {
	const list = require('./emergencyNumbers.json');
	let numbers = [];
	list.map(b => {
		numbers = numbers.concat(Object.values(b.Ambulance));
		numbers = numbers.concat(Object.values(b.Police));
		numbers = numbers.concat(Object.values(b.Fire));
		numbers = numbers.concat(Object.values(b.Dispatch));
		return true;
	});
	numbers = Array.from(new Set(numbers.flat().filter(n => !!n).map(n => n.trim())));
	localStorage.setItem('emergencyCallNumbers', JSON.stringify(numbers));
	emergencyNumbersJson = localStorage.getItem('emergencyCallNumbers');
}
const emergencyNumbers = JSON.parse(emergencyNumbersJson);

export function dateRangeOverlaps(a_start, a_end, b_start, b_end) {
	if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
	if (a_start <= b_end   && b_end   <= a_end) return true; // b ends in a
	if (b_start <  a_start && a_end   <  b_end) return true; // a in b
	return false;
}
export function closestTo(number, array) {
	return array.reduce((a,b) => {
		return Math.abs(b - number) < Math.abs(a - number) ? b : a;
	});
}
export function isValidMacAddress(mac) {
	if(mac?.replaceAll(':', '').indexOf('*') === 11) {
		mac = mac.replace('*', '0');
	}
	return validator.isMACAddress(mac);
}
export function isValidPhoneNumber(phone, canBeReference) {
	if(!phone) return false;
	if(canBeReference && getReference(phone)) return true;
	phone = `${phone}`;
	if(emergencyNumbers.indexOf(phone) >= 0) return true;
	const formatted = formatPhoneToPlain(phone, true);
	const isLongNumber = formatted.length >= 14; //workaround for https://github.com/catamphetamine/libphonenumber-js/issues/421
	const isShortNumber = phone.length < 5 && phone.indexOf('+') === -1;
	if(isLongNumber || isShortNumber) return true;
	return _isValidPhoneNumber(formatted) || _isValidPhoneNumber(phone);
}
export function isValidIccid(iccid) {
	if(!iccid) return false;
	if(typeof iccid !== 'string') return false;
	return iccid.isNumeric() && iccid.length === 20;
}

export function hexToDec(hex) {
	return parseInt(hex, 16);
}
export function decToHex(dec, stringLength = null) {
	if(dec === undefined || dec === null) return dec; 
	const hex = (+dec).toString(16).uc();
	if(!stringLength) return hex;
	return hex.padStart(stringLength, '0');
}

export function getActivePage(path) {
	if(!path) {
		path = window.location.pathname;
	}
	if(path.indexOf('/') === 0) path = path.substring(1);
	if(path.startsWith('d/')) return 'device-form';
	if(path.startsWith('presentation/_')) return 'presentation';
	if(path.startsWith('alarm-location/')) return 'alarm-location';
	if(path.startsWith('live-location/')) return 'live-location';
	path = path.replaceAll(/[0-9]*/g, '');
	if(path.endsWith('/')) path = path.substring(0, path.length - 1);
	return pageMap[path] === null ? path : 'home';
}
export const parseERPNextDate = date => dayjs.tz(date, 'YYYY-MM-DD', 'UTC');

export const validatePassword = (pwd: string) => {
	if(!pwd) pwd = '';
	const hasLowerCase = /[a-z]/.test(pwd);
	const hasUpperCase = /[A-Z]/.test(pwd);
	const hasSymbol = /[!@#$~%^&*()\\_+\-={};'":|,.<>?]/.test(pwd);
	const hasCorrectLength = pwd.length >= 8;
	return {
		hasLowerCase, hasUpperCase, hasSymbol, hasCorrectLength,
		valid: hasLowerCase && hasUpperCase && hasSymbol && hasCorrectLength
	};
};
export const arrayEquals = (a, b) => {
	if(a?.toJS) a = a.toJS();
	if(b?.toJS) b = b.toJS();
	if(a?.sort) a.sort();
	if(b?.sort) b.sort();
	return Array.isArray(a) && Array.isArray(b) && _isEqual(a, b);
}

export const newTab = url => {
	window.open(url, '_blank');
};

export function setCursorPosition(elem, caretPos) {
	if(elem != null) {
		if(elem.createTextRange) {
			var range = elem.createTextRange();
			range.move('character', caretPos);
			range.select();
		}
		else {
			if(!isNaN(elem.selectionStart)) {
				elem.focus();
				elem.setSelectionRange(caretPos, caretPos);
				elem.selectionStart = caretPos;
			}
			else
				elem.focus();
		}
	}
}

export function generateRandomString(length) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++ ) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export function formatPhoneToPlain(phone, addPlus) {
	try {
		phone = phone.split('(').join('');
		phone = phone.split(')').join('');
		phone = phone.split(' ').join('');
		phone = phone.split('-').join('');
		phone = phone.split('+').join('');
		const isShort = phone.length < 5 && phone.indexOf('+') === -1;
		return addPlus && !isShort ? `+${phone}` : phone;
	} catch (e) {
		return '';
	}
}

export function sundayFirstToMondayFirst(weekday) {
	return weekday === 0 ? 6 : weekday - 1;
}

export function getLangFromLocale(locale) {
	const split = locale.split('-');
	// noinspection TypeScriptValidateTypes
	return split && split.length ? split[0] : null;
}
export function getRegionFromLocale(locale) {
	const split = locale.split('-');
	// noinspection TypeScriptValidateTypes
	return split && split.length >= 2 ? split[1] : null;
}
export function getLocaleFromLang(language) {
	var lang = getLangFromLocale(language);
	return locales[lang] || locales.en;
}

export const uniqueArray = function(array) {
	return Array.from(new Set(array));
};
// eslint-disable-next-line
String.prototype.substringEnd = function(size) {
	if(this.length < +size) return this;
	size = Math.abs(size);
	return this.substring(this.length - +size, this.length);
};
// eslint-disable-next-line
String.prototype.isNumeric = function() {
	if(this.length === 0) return false;
	return /\d*/.test(this);
};
// eslint-disable-next-line
String.prototype.lc = function() {
	return this.toLowerCase();
};
// eslint-disable-next-line
String.prototype.uc = function() {
	return this.toUpperCase();
};
// eslint-disable-next-line
String.prototype.cap = function() {
	if(this.length === 0) return this;
	if(this.length === 1) return this.uc();
	return this.charAt(0).uc() + this.slice(1);
};
// eslint-disable-next-line
String.prototype.ellipsis = function(maxLength, cropFrom = 'end') {
	if(this.length <= maxLength) return this;
	if(cropFrom === 'end') {
		return `${this.substr(0, maxLength)}...`;
	}
	if(cropFrom === 'start') {
		return `...${this.substr(this.length - maxLength, maxLength)}`;
	}
	if(cropFrom === 'center') {
		const half = Math.round(maxLength / 2);
		return `${this.substr(0, half)}...${this.substr(-half, half)}`;
	}
};

export function isTokenValidLonger(newToken) {
	if(!newToken) return false;
	if(!BaseAPI.token) return true;
	const decodedNew = jwtDecode(newToken);
	const decodedOld = jwtDecode(BaseAPI.token);
	return decodedNew.exp > decodedOld.exp;
}

// eslint-disable-next-line
Number.prototype.hasFlag = function(flag) {
	return (+this & flag) === flag;
};
// eslint-disable-next-line
Number.prototype.toggleFlag = function(flag, toggle = null) {
	if(toggle === null) 
		return this.hasFlag(flag) ? this - flag : this + flag;
	const hasFlag = this.hasFlag(flag);
	if(hasFlag && !toggle) return this - flag;
	if(!hasFlag && toggle) return this + flag;
	return this;
};

export function replaceUndefinedWithNull(value) {
	if(value === undefined || value === null) return null;
	if(Array.isArray(value)) {
		const newValue = [...value];
		for(const k in newValue) {
			newValue[k] = replaceUndefinedWithNull(newValue[k]);
		}
		return newValue;
	}
	else if(typeof(value) === 'object') {
		const newValue = {...value};
		for(const k in newValue) {
			newValue[k] = replaceUndefinedWithNull(newValue[k]); 
		}
		return newValue;
	}
	return value;
}