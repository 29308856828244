const pageMap = {
	'register': null,
	'alarms': null,
	'alarm': null,
	'geofences': null,
	'devices': null,
	'beacons': null,
	'teams': null,
	'users': null,
	'feedback': null,
	'about': null,
	'help': null,
	'reports': null,
	'reporting': null,
	'modules': null,
	'scenarios': null,
	'scenarios/device': null,
	'account': null,
	'quickTexts': null,
	'presentation': null,
	'assistanceRequests': null,
	'alarm-location': null,
	'live-location': null,
	'cache': null,
	
	'protocols': null,
	'contacts': null,
	
	'admin/root': null,
	'admin/proms': null,
	'admin/arcs': null,
	'admin/accounts': null,
	'admin/accountManagers': null,
	'admin/devices': null,
	'admin/beacons': null,
	'admin/scenarios': null,
	'admin/sites': null,
	'admin/translations': null,
	'admin/reports': null,
	'admin/simcards': null,
	'admin/simcards/alerts': null,
	'admin/finance': null,
	'admin/administration/salesItems': null,
	'admin/administration/customers': null,
	'admin/administration/salesInvoices': null,
	'admin/administration/pricing': null,
	'admin/administration/subscriptions': null
};

export default pageMap;