import styled from 'styled-components';
import { Colors } from '../../utils/style/colors';
import { getWhitelabel } from '../../env';

export const AppBackground = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-size: cover;
	background: ${getWhitelabel().palette.splashscreenBackground};
`;
export const AppBackgroundDots = styled.canvas`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-size: cover;
	@keyframes dots-load {
	  from { opacity: 0; }
	  to {opacity: 1; }
	}
	animation: dots-load 1s;
	transition: all 2s;
	${getWhitelabel()?.palette?.splashscreenBackground ? `
	  background-image: ${getWhitelabel().palette.splashscreenBackground};
	` : `
	  background-image: radial-gradient(${Colors.gray40}, ${Colors.gray60});
	`}
	
`;

export const SnowFloor = styled.div`  
  width: 100vw;
  height: 40px;
  bottom: -10px;
  content: ' ';
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('${require('./snow.svg')}');
`;