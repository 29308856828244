import LinearProgress from '@mui/material/LinearProgress';

import React from 'react';

import { compose } from 'redux';
import { Colors } from '../../utils/style/colors';

export default compose(
)(({ isVisible }) => (
	<LinearProgress
		className="progressBar"
		style={{
			transition: 'all .3s',
			zIndex: 0,
			opacity: isVisible ? 1 : 0,
			width: 'auto',
			transform: 'translateY(-5px)',
			borderRadius: 0
		}}
		classes={{
			colorPrimary: Colors.primary(),
			barColorPrimary: Colors.primary(),
		}}
	/>
));